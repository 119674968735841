
import React from 'react'
import { navigate } from 'gatsby'

import Meta from 'layout/Meta'
import SignUpEmail from 'user/SignUpEmail'
import SignInFacebook from 'user/SignInFacebook'


export default function SignUpPage() {

	return <main className='page'>
		
		<Meta title="Sign up" />
				
		<h1>Create account</h1>

		<SignUpEmail done={() => navigate('/chat/') } />
		<SignInFacebook done={() => navigate('/chat/') } />

	</main>
}
